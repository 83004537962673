import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { Breadcrumb, menuItem } from "@vex/interfaces/dashboard.interface";
import { ContactService } from "./contact.service";

@Injectable({
  providedIn: "root",
})

export class WebsiteService {
  private subscription: Subscription = new Subscription();

  public breadCrumb_B: BehaviorSubject<Breadcrumb> = new BehaviorSubject<Breadcrumb>({ title: "Home", crumbs: [{name: 'Home', url: ''}] });
  get breadCrumb_O(): Observable<Breadcrumb> {
    return this.breadCrumb_B.asObservable();
  }

  private readonly navigation_B: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get navigation_O(): Observable<any[]> {
    return this.navigation_B.asObservable();
  }

  constructor(
    private http: HttpClient,
    public contactSrv: ContactService,
  ) {
    const AuthSub = this.contactSrv.isAuthenticated_O.subscribe({
      next: (value: boolean) => {
        if (value) {
          this.loadNavigation();
        }
      },
    });

    this.subscription.add(AuthSub);
  }

  public loadNavigation() {
    this.getContactMenuData().subscribe({
      next: (val: any) => {
        const response = val.data[0]?.objects || [];
        const _menuItem: menuItem[] = response.map((elem: any) => ({
          oid: elem.oid,
          name: elem.name,
          route: elem.route,
          parent: elem.parent,
          icon: elem.iconClass,
          classes: elem.iconClasses,
        }));

        this.navigation_B.next(_menuItem);
      },
    });
  }

  public getContactMenuData() {
    return this.http.get<any>(
      environment.apiUrl + `/Website/Custom/ContactMenuData`,
      { 
        responseType: "json" 
      }
    );
  }

  public getClientDashboard() {
    const headers = new HttpHeaders().set('toggleLoading', 'true');
    return this.http.get<any>(
      environment.apiUrl + `/Website/Custom/GetCompanyListByContact`,
      { 
        headers: headers,
        responseType: "json" 
      }
    );
  }

  public getMinimumCategoryRequirements(params: any): Observable<any> {
    let reqParam: string = `etid=${params.etId}&customerTypeId=${params.customerTypeID}&cid=${params.categoryID}`;
    return this.http.get<any>(
      environment.apiUrl + `/Website/Custom/GetMinimumCategoryRequirement?` + reqParam,
      { 
        responseType: "json" 
      }
    );
  }

  public getEngineerDataById(id: string) {
    let reqParam: string = `EngineerID=${id}`;
    return this.http.get<any>(
      environment.apiUrl + `/Website/Custom/GetEngineerById?` + reqParam,
      { 
        responseType: "json" 
      }
    );
  }

  public getRegistrationSetup(): Observable<void> {
    return this.http.get<any>(
      environment.apiUrl + `/Website/Custom/RegistrationSetup`,
      { 
        responseType: "json" 
      }
    );
  }

  public registerUser(reqParam: any): Observable<any> {
    return this.http.post<any>(
      environment.apiUrl + '/Website/Custom/Register',
      reqParam,
      { 
        responseType: "json" 
      },
    );
  }

  
  public sendOTP(reqParam: any): Observable<any> {
    return this.http.post<any>(
      environment.apiUrl + '/Website/Custom/SendOTP',
      reqParam, { 
        responseType: "json" 
      }
    );
  }

  public resendOTP(reqParam: any): Observable<any> {
    return this.http.post<any>(
      environment.apiUrl + '/Website/Custom/ResendOTP',
      reqParam, { 
        responseType: "json" 
      }
    );
  }

  getApplicationStatus(): Observable<any> {
    const headers = new HttpHeaders().set('toggleLoading', 'true');
    let reqParam: string = `/Website/Custom/GetApplicationStatus`;
    return this.http.get<any>(environment.apiUrl + reqParam, {
      headers: headers
    });
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Router,
} from "@angular/router";
import { ContactService } from "../_services/contact.service";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  private subscription: Subscription = new Subscription;
  private isAuthenticated: boolean = false;

  constructor(
    private contactSrv: ContactService,
    private router: Router,
  ) {
    const AuthSub = this.contactSrv.isAuthenticated_O.subscribe({
      next: (val: any) => {
        this.isAuthenticated = val;
      },
    });

    this.subscription.add(AuthSub);
  }

  canActivate(): boolean {
    return this.checkAuth();
  }

  canActivateChild(): boolean {
    return this.checkAuth();
  }

  canLoad(): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {
    if (this.isAuthenticated) {
      return true;
    } else {
      // Redirect to the login page if the user is not authenticated
      this.router.navigate(["/login"]);
      return false;
    }
  }
}

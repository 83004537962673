import { Component } from "@angular/core";
import { VexLayoutService } from "@vex/services/vex-layout.service";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RouterOutlet } from "@angular/router";
import { VexConfigService } from "@vex/config/vex-config.service";
import { VexSidebarComponent } from "@vex/components/vex-sidebar/vex-sidebar.component";
import { BaseLayoutComponent } from "../base-layout/base-layout.component";
import { MatDrawerMode, MatSidenavModule } from "@angular/material/sidenav";
import { VexProgressBarComponent } from "@vex/components/vex-progress-bar/vex-progress-bar.component";
import { VexConfig } from "@vex/config/vex-config.interface";
import { NavigationItem } from "src/app/core/navigation/navigation-item.interface";
import { NavigationService } from "src/app/core/navigation/navigation.service";
import { CommonModule } from "@angular/common";
import { SidenavComponent } from "src/app/_components/sidenav/sidenav.component";
import { ToolbarComponent } from "src/app/_components/toolbar/toolbar.component";
import { FooterComponent } from "../components/footer/footer.component";

@Component({
  selector: "vex-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
  imports: [
    BaseLayoutComponent,
    CommonModule,
    SidenavComponent,
    ToolbarComponent,
    FooterComponent,
    VexSidebarComponent,
    VexProgressBarComponent,
    MatSidenavModule,
    RouterOutlet,
  ],
  standalone: true,
})

export class LayoutComponent {
  config$: Observable<VexConfig> = this.configService.config$;
  navigationItems$: Observable<NavigationItem[]> =
    this.navigationService.items$;
  isLengthMore$: Observable<boolean> = this.navigationItems$.pipe(
    map((array) => array.length > 1),
  );

  sidenavCollapsed$: Observable<boolean> = this.layoutService.sidenavCollapsed$;
  sidenavDisableClose$: Observable<boolean> = this.layoutService.isDesktop$;
  sidenavFixedInViewport$: Observable<boolean> =
    this.layoutService.isDesktop$.pipe(map((isDesktop) => !isDesktop));
  sidenavMode$: Observable<MatDrawerMode> = combineLatest([
    this.layoutService.isDesktop$,
    this.configService.select((config) => config.layout),
  ]).pipe(
    map(([isDesktop, layout]) =>
      !isDesktop || layout === "vertical" ? "over" : "side",
    ),
  );
  sidenavOpen$: Observable<boolean> = this.layoutService.sidenavOpen$;
  configPanelOpen$: Observable<boolean> = this.layoutService.configPanelOpen$;
  quickpanelOpen$: Observable<boolean> = this.layoutService.quickpanelOpen$;

  constructor(
    private readonly layoutService: VexLayoutService,
    private readonly configService: VexConfigService,
    private readonly navigationService: NavigationService,
  ) {}

  onSidenavClosed(): void {
    this.layoutService.closeSidenav();
  }

  onQuickpanelClosed(): void {
    this.layoutService.closeQuickpanel();
  }
}

<div class="w-full h-full bg-pattern flex flex-col items-center justify-center p-4 full-height">
  <div 
    @fadeInUp 
    class="card overflow-hidden w-full  max-w-lg  mt-4 relative " 
    *ngIf="configData"
  >
    <!-- <div class="absolute right-2 top-2">
      <mat-icon svgIcon="mat:help" class="icon-md cursor-pointer transition duration-500 ease-out-swift hover:text-primary-600" [matTooltip]="'Help' | translate "  [matTooltipPosition]="'above'" (click)="routeToHelp()"></mat-icon>
    </div> -->
    <div class="text-center mt-4">
      <h2 class="title m-0">
        {{ 'Registering New Engineering Office' | translate }}
      </h2>

      <h4 class="body-2 text-secondary mt-2 flex  justify-center gap-1 mx-auto max-w-[85%]"  [@fadeInContRight]="getHintType()">
        <ng-container *ngIf="getHintType() == ''; else showBasicTemplate">
          {{ 'Simply fill out the form below' | translate }}
        </ng-container>
        <ng-template #showBasicTemplate>
          <mat-label class="font-semibold text-black">
            {{'Hint:'}}
          </mat-label>
          <mat-label class="text-black/90">
            {{ getHintType() | translate }}
          </mat-label>
        </ng-template>
      </h4>
    </div>
    
    <form 
      #f1="ngForm"
      [formGroup]="registerForm" 
      class="p-6 flex flex-col"       
    >
      <mat-form-field>
        <mat-label>
          {{ "Company Type" | translate }}
        </mat-label>
        <mat-select formControlName="eoid">
          <mat-option
            *ngFor="let option of configData.estOfficeTypes"
            [value]="option.id"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{ "Applicant Type" | translate }}
        </mat-label>
        <mat-select formControlName="applicantType">
          <mat-option
            *ngFor="let option of applicantTypes"
            [value]="option.id"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Personal ID' | translate }}</mat-label>
        <input 
          formControlName="cpr" 
          matInput 
        />
      </mat-form-field> 


      

      <mat-form-field *ngIf="showExtraFormFields" [@fadeInContRight]="showExtraFormFields">
        <mat-label>{{ 'Login Email' | translate }}</mat-label>
        <input 
          formControlName="email" 
          matInput 
          cdkFocusInitial
        />
      </mat-form-field>
      <mat-form-field *ngIf="showExtraFormFields" [@fadeInContRight]="showExtraFormFields">
        <mat-label>{{ 'Mobile No' | translate }}</mat-label>  
        <ngx-mat-intl-tel-input
          [cssClass]="'custom'"
          [preferredCountries]="['bh', 'sa']"  
          formControlName="contactMobile" 
          #phoneInput
          [required]="true"
        ></ngx-mat-intl-tel-input> 
      </mat-form-field>  

      <mat-form-field  *ngIf="showExtraFormFields" [@fadeInContRight]="showExtraFormFields" >
        <mat-label> {{ "Nationality" | translate }} </mat-label>              
        <input 
          [matAutocomplete]="natId" 
          formControlName="natId"
          matInput               
        />
        <mat-autocomplete 
          [displayWith]="displayFn"                
          #natId="matAutocomplete"
        >
          <mat-option
            *ngFor="let option of searchableDropdown_O | async"                  
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <button
          (click)="registerForm.get('natId')?.reset();"
          *ngIf="registerForm.get('natId')?.value && registerForm.get('natId')?.value != ''"
          mat-icon-button
          matIconSuffix
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>

        <button 
          *ngIf="!registerForm.get('natId')?.value" 
          mat-icon-button 
          matIconSuffix 
          type="button"
        >
          <mat-icon svgIcon="mat:arrow_drop_down"></mat-icon>
        </button>
      </mat-form-field>

      
      <div class="mb-3">
        <small *ngIf="lang == 'en'" class="text-xs">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy" target="_blank" class="transition-all text-primary-600 hover:text-primary-800">Privacy Policy</a> and
            <a href="https://policies.google.com/terms" target="_blank"  class="transition-all text-primary-600 hover:text-primary-800">Terms of Service</a> apply.
          </small>
          <small *ngIf="lang == 'ar'" >
            هذا الموقع محمي بواسطة reCAPTCHA و 
            <a href="https://policies.google.com/privacy" target="_blank" class="transition-all text-primary-600 hover:text-primary-800">تطبق سياسة الخصوصية</a> و
            <a href="https://policies.google.com/terms" target="_blank" class="transition-all text-primary-600 hover:text-primary-800">شروط الخدمة</a> من Google.
          </small>                                    
      </div>
      <button 
        (click)="submitUser()" 
        color="primary" 
        class="flex mx-auto my-5 transition-none"
        mat-raised-button 
        type="button"
        [disabled]="isFormSubmitted"
      > 
        <span *ngIf="!isFormSubmitted">{{ 'CREATE ACCOUNT' | translate }}</span>
        
        <mat-progress-spinner 
          class="!w-5 !h-5"
          mode="indeterminate" 
          color="accent"
          *ngIf="isFormSubmitted"
        ></mat-progress-spinner> 
      </button>

      <p class="flex items-center justify-center gap-2">
        <span class="text-secondary">{{ 'Already have an account?' | translate }}</span>
        <a [routerLink]="['/login']" class="transition-all text-primary-600 hover:text-primary-800">{{ 'Sign in here' | translate }}</a>
      </p>
    </form>
  </div>
</div>

<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar text-default w-full px-2 md:px-6 flex items-center gap-2"
>
  <button
    (click)="openSidenav()"
    [class.hidden]="!(ltMd$ | async) || !(isAuthenticated_O | async)"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="[RoutingTab.Dashboard]"
    class="ltr:mr-4 rtl:ml-4 flex items-center"
  >
    <img
      alt="Logo"
      class="w-[170px] p-1 bg-white rounded overflow-hidden"
      src="assets/img/logo/crpep-logo.png"
      [class.hidden]="ltMd$ | async"
    />
  </a>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon
          [svgIcon]="
            tSrv.currentLang === 'en'
              ? 'flag:united-states'
              : 'flag:bahrain'
          "
        ></mat-icon>
      </button>
    </div>

    <div *ngIf="!(ltMd$ | async)" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>
    <mat-menu
      #languageMenu="matMenu"
      overlapTrigger="false"
      xPosition="before"
      yPosition="below"
    >
      <button mat-menu-item (click)="useLanguage('en')" [ngClass]="{'bg-primary-100': tSrv.currentLang === 'en' }">
        <mat-icon svgIcon="flag:united-states"></mat-icon>
        <span>English</span>
      </button>

      <button mat-menu-item (click)="useLanguage('ar')" [ngClass]="{'bg-primary-100': tSrv.currentLang === 'ar' }">
        <mat-icon svgIcon="flag:bahrain"></mat-icon>
        <span>عـــــــــــربي</span>
      </button>
    </mat-menu>
  </div>
</div>
<vex-navigation
  *ngIf="(isVerticalLayout$ | async) && (isNavbarBelowToolbar$ | async)"
  [class.hidden]="!(isDesktop$ | async)"
>
</vex-navigation>
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

const TOKEN_KEY = "auth-token";
const USER_KEY = "auth-user";

@Injectable({
  providedIn: "root",
})
export class TokenStorageService {
  private loggedUser_S: BehaviorSubject<any> = new BehaviorSubject<any>({});
  get loggedUser_O() {
    return this.loggedUser_S.asObservable();
  }

  constructor() {
    let loggedUser = this.getUser();
    this.loggedUser_S.next(loggedUser);
  }

  public clearLocalStorage(): void {
    window.localStorage.clear();
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    this.loggedUser_S.next(user);
  }

  public logoutUser(): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
    this.loggedUser_S.next(null);
  }

  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);

    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public isNewUser(): boolean {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      let parsedData = JSON.parse(user);
      return parsedData.registerCompany as boolean;
    }
    return false;
  }
}

import { 
    ChangeDetectionStrategy, 
    ChangeDetectorRef, 
    Component, 
    Inject, 
    OnInit
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDividerModule } from "@angular/material/divider";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { ContainerService } from "src/app/_services/container.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { 
  Observable, 
  Subscription, 
  filter, 
  map, 
  of, 
  shareReplay, 
  startWith
} from "rxjs";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SNACKBAR_DURATION_NORMAL } from "src/static-data/constants";

@Component({
  selector: "address-form-modal",
  templateUrl: "./address-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
    MatSnackBarModule,
    TranslateModule,
    MatAutocompleteModule
  ]
})

export class AddressFormComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();
  public addressForm!: FormGroup;
  public formSetup: any;
  public isCustomTitle: boolean = false;
  public isLoaded: boolean = false;

  private defaultAddressFormat = 1;
  private defaultCountryInput = { id: 235, name: "Bahrain" };
  private defaultCityInput = { countryID: 235, id: 235, name: "Manama"};

  public searchableDropdown_O: Observable<any[]> = new Observable<any[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<AddressFormComponent>,
    private fb: FormBuilder,
    public containerSrv: ContainerService,
    public snackbar: MatSnackBar,
    public cdRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    const addSub = this.containerSrv.FormSetup_O.subscribe((resp: any) => {
        this.formSetup = resp;        
    });

    this.subscriptions.add(addSub);
  }

  ngOnInit() {
    this.isCustomTitle = !['main', 'passport address'].includes(this.inputData.address.title ? this.inputData.address.title.toLowerCase() : '');
    let isAddressAvailaible: boolean = this.inputData.address.addressFormatID != null;

    let addressFormat: any = this.defaultAddressFormat;
    let countryIDFormat: any = this.defaultCountryInput;
    let cityIDFormat: any =  this.defaultCityInput;
    if(isAddressAvailaible) {
      addressFormat = this.formSetup.addressFormat.find(
        (item: any) => item.id == this.inputData.address.addressFormatID)?.id || this.defaultAddressFormat;
      
      countryIDFormat = this.formSetup.country.find(
        (item: any) => item.id == this.inputData.address?.countryID) || this.defaultCountryInput;
        isAddressAvailaible ?( (this.inputData.address?.cityID != null && this.inputData.address?.cityID != '') ? parseInt(this.inputData.address.cityID) : 253)  : 253 
      
      cityIDFormat = this.formSetup.cities.find(
        (item: any) => item.id == this.inputData.address?.cityID) || this.defaultCityInput;
    }
    
    this.addressForm = this.fb.group({
      did: [this.inputData.address.did],
      addressFormatID: [addressFormat, Validators.required],
      address6: [this.inputData.address.address6, Validators.required],
      address7: [this.inputData.address.address7],
      poBox: [this.inputData.address.poBox],
      cityID: [cityIDFormat.id, Validators.required],
      countryID: [countryIDFormat, Validators.required],
      address5: [this.inputData.address.address5],
      title: [this.inputData.address.title, this.isCustomTitle ?  Validators.required : null]
            // address1: [this.inputData.address.address1],
      // address2: [this.inputData.address.address2],
      // address3: [this.inputData.address.address3],
      // address4: [this.inputData.address.address4],
      // 
      // fax: [this.inputData.address.fax],
      // phone: [this.inputData.address.phone],
      // email: [this.inputData.address.email],
      // longitude: [this.inputData.address.longitude],
      // latitude: [this.inputData.address.latitude],      
    });

    this.searchableDropdown_O = this.addressForm.get('countryID')?.valueChanges.pipe(
      startWith(''),
      map((searchString: string) => this.filteredData(searchString))
    ) ?? of([]);
    this.selectCountry({value: addressFormat});
    this.isLoaded = true;
    this.cdRef.markForCheck();
  }

  private filteredData(searchString: string): any[] {
    let filteredData: any[] = [];
    if (typeof searchString === 'string') {
      filteredData = this.formSetup.country.filter((option: any) =>
            option.name.toLowerCase().includes(searchString.toLowerCase()))
    } else {
      filteredData = this.formSetup.country.slice();
    } 
    return filteredData;
  }

  public clearFormInput() {
    this.addressForm.get('countryID')?.reset();
    this.addressForm.get('cityID')?.reset();
  }

  public displayFn(option: any): string {
    return (option && option.name) ? option.name : '';
  }

  public saveAddress(): void {
    let isValid: boolean = this.addressForm.valid;
    if(isValid) {
      let updatedObject = this.replaceNullsWithEmptyString(this.addressForm.value);
      if (updatedObject.did == "") {
        updatedObject.did = 0;
      }
      if(updatedObject.countryID?.id) {
        updatedObject.countryID = updatedObject.countryID.id;
      }
      if(this.isCustomTitle) {
        if(updatedObject.title.trim() != '' && !['main', 'passport address'].includes(updatedObject.title.toLowerCase())) {
          this.dialogRef.close(updatedObject);
        } else {
          if(updatedObject.title.trim() == '') {
            let response: string = this.translate.instant("Please enter a title");
            this.snackbar.open(response , undefined, { duration: SNACKBAR_DURATION_NORMAL });
          } else {
            let response: string = this.translate.instant("Please choose a different title");

            this.snackbar.open(response, undefined, { duration: SNACKBAR_DURATION_NORMAL });
          }        
        }
      } else {
        this.dialogRef.close(updatedObject);
      }
    } else {
      let response: string = this.translate.instant("Please fill the mandatory fields");
      this.snackbar.open(response , undefined, { duration: SNACKBAR_DURATION_NORMAL });
    }
  }
  
  private replaceNullsWithEmptyString(obj: any): any {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (obj[prop] === null) {
          obj[prop] = "";
        } else if (typeof obj[prop] === "object") {
          obj[prop] = this.replaceNullsWithEmptyString(obj[prop]);
        }
      }
    }
    return obj;
  }

  public selectCountry(event: any) {
    let addressItem = this.formSetup.addressFormat.find((elem: any) =>  elem.id == event.value);

    if(addressItem) {
      const selectedCountry = this.formSetup.country.find((elem: any) =>  elem.id == addressItem.countryID);
      const selectedCityValue = this.addressForm.get('cityID')?.value;
      if(selectedCityValue) {
        let selectedCityCountryID = this.formSetup.cities.find((elem: any) =>  elem.id == selectedCityValue).countryID;


        if(selectedCityCountryID != selectedCountry.id) {
          this.addressForm.patchValue({
            countryID: selectedCountry,
            cityID: null
          });
        } else { 
          this.addressForm.patchValue({
            countryID: selectedCountry,
          });
        }
      } else {
        this.addressForm.patchValue({
          countryID: selectedCountry,
        });
      }
    }

    this.cdRef.markForCheck();
  }

  public selectCity(event: MatAutocompleteSelectedEvent) {
    let selectedValue = event.option.value;
    let countryInfo = this.formSetup.cities.find((elem: any) =>  elem.countryID == selectedValue.id);

    if(countryInfo) {
      this.addressForm.patchValue({
        cityID: countryInfo.id
      });
    } else {
      this.addressForm.patchValue({
        cityID: null
      });
    }

    this.cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getSelectedCountryCity() { 
    let countryId = this.addressForm.value?.countryID?.id || null;

    return this.containerSrv.FormSetup_O.pipe(
      filter((config: any) => config !== null),
      map((config: any) => config.cities),
      map((cities: any[]) =>
        countryId
      ? cities.filter((city: any) => city.countryID === countryId)
      : cities      
      ));
  }

}



<div 
  class="flex items-center justify-between  !px-3 sm:!px-6" 
  mat-dialog-title
>
  <h5 class="m-0 flex-auto"> 
    {{ inputData.title | translate }} 
  </h5>

<button
  class="text-secondary"
  mat-dialog-close
  (click)="dialogRef.close({isSubmit: false, appID: finalAppID})"
  mat-icon-button
  type="button"
>
  <mat-icon svgIcon="mat:close"></mat-icon>
</button>
</div>

<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="flex flex-col !pb-0 !max-h-[70vh] !px-3 sm:!px-6">
  <div class="text-sm font-medium text-justify text-secondary !mt-[-10px] mb-2">
    {{ "We require additional information for your application. Please review the comments provided and resubmit your application." | translate }}        
  </div>
  <mat-label class="text-sm text-black mb-1">
    {{ "Comments:" | translate }}
  </mat-label>
  <div class="min-h-40  border rounded p-2 !mb-4 bg-slate-100 overflow-hidden text-black">
    {{commentMessage}}
  </div> 
</mat-dialog-content>
<mat-divider class="text-border"></mat-divider>

<mat-dialog-actions align="center" >
  <button
    color="primary"
    mat-flat-button
    (click)="continueApplication()"
  >
    {{ 'Continue Application' | translate }}
  </button>
</mat-dialog-actions>

<!--Following Time2Code Standards-->

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})

export class ContainerService {
  private FormSetup_B: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  get FormSetup_O() {
    return this.FormSetup_B.asObservable();
  }

  constructor(private http: HttpClient) {}

  public getFormSetupData() {
    this.retreiveFormSetup();
  }

  private async retreiveFormSetup(): Promise<any> {
    const savedData = sessionStorage.getItem("container-config");
    if (!savedData) { //if there is no saved data.
      const response = await this.getFormSetup();
      if(response) {
        this.FormSetup_B.next(response);
        sessionStorage.setItem("container-config", JSON.stringify(response));
      } else {
        //todo: redirect to error page something happened or something.
      }
    } else { //if there is saved data.
      this.FormSetup_B.next(JSON.parse(savedData));
      const response = await this.getFormSetup();
      if(response) {
        sessionStorage.setItem("container-config", JSON.stringify(response));
      } else {
        console.log("could not retreive the form data");
        //todo: redirect to error page something happened or something.
      }
    }
  }

  private getFormSetup(): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      this.http.get<any>(environment.apiUrl + `/Website/Custom/GetFormSetup`).subscribe({
        next: (value: any) => {
          if (value.succeeded && value.data) {
            value.data.country.sort((a: any, b: any) => a.name.localeCompare(b.name));
            value.data.cities.sort((a: any, b: any) => a.name.localeCompare(b.name));
  
            resolve(value.data);
          } else {
            resolve(null);
          }
        },
        error: (err: any) => {
          reject(null)
        }
      });
    });    
  }
  
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  public currentLang: string = "en";

  public keepReqFieldLabel!: string;
  public fillAllFieldLabel!: string;
  public entryDelSucessLabel!: string;
  public selectPDFLabel!: string;
  public ReqFieldMustFillLabel!: string;
  public signatureUploaded!: string;
  public nosignatureToSaveLabel!: string;

  constructor(private _translate: TranslateService) {
    let language = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";

    if (language) {
      this.currentLang = language;
    }
    this.inititalizeTranslation();
  }

  private inititalizeTranslation() {
    this._translate.get("Keep all the not required fields empty or fill in the data completely").subscribe((res: string) => {
      this.keepReqFieldLabel = res;
    });
;

    this._translate
      .get("Please fill all the field")
      .subscribe((res: string) => {
        this.fillAllFieldLabel = res;
      });
    this._translate
      .get("Entry deleted Sucessfully")
      .subscribe((res: string) => {
        this.entryDelSucessLabel = res;
      });
    this._translate.get("Please select a PDF file").subscribe((res: string) => {
      this.selectPDFLabel = res;
    });
    this._translate
      .get("Required fields must be filled")
      .subscribe((res: string) => {
        this.ReqFieldMustFillLabel = res;
      });
    this._translate.get("Signature Uploaded").subscribe((res: string) => {
      this.signatureUploaded = res;
    });
    this._translate.get("No Signature to Save").subscribe((res: string) => {
      this.nosignatureToSaveLabel = res;
    });
  }
}

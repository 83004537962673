import { Component, Inject, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatDividerModule } from "@angular/material/divider";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatRadioModule,
    FormsModule,
    TranslateModule,
    MatDialogModule, 
    MatDividerModule
  ],
  selector: "license-modal",
  template: `
    <div 
      class="flex items-center  !px-3 sm:!px-6" 
      mat-dialog-title
    >
      <h2 class="title m-0 flex-auto"> {{'License History' | translate }}</h2>
      <button 
        class="text-secondary" 
        mat-dialog-close 
        mat-icon-button 
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="text-border"></mat-divider>
    <mat-dialog-content class="flex flex-col !px-3 sm:!px-6">
      <ol class="relative border-s border-gray-200">                  
          <li class="mb-10 ms-6" *ngFor="let x of licenseData; index as i">  
            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full  ring-8 ring-white -start-3 font-semi-bold text-sm">
              <mat-icon svgIcon="personal:id_card" class="icon-xs text-blue-800" ></mat-icon>
            </span>
            <h3 class="flex items-center mb-1 text-base font-semibold text-gray-900">
              {{ x.companyName }}  
            </h3>
            <div class="flex items-center justify-between my-2">
              <time class="block text-sm font-normal leading-none text-gray-400"> {{ x.licenseStartDate | date }} - {{ x.licenseExpiryDate | date }} </time>
              <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded ms-3" *ngIf="i == 0">{{ 'Current' | translate }}</span>
            </div>
            <p class="text-base font-normal text-gray-500 flex items-center gap-2">
              <span class="font-semibold">{{'License No:'}}</span>
              {{ x.engineerLicenseNo }}
            </p>
            <p class="mb-2 text-base font-normal text-gray-500 flex items-center gap-2">
              <span>{{ x.bankCode }}</span>
              -
              <span>{{ x.companyType }}</span>              
            </p>            
          </li>          
      </ol>
    </mat-dialog-content>
    <mat-divider class="text-border"></mat-divider>

    <mat-dialog-actions align="end">
    <button
        color="warn"
        mat-button
        type="button"
        mat-dialog-close 
      >
        {{'Close' | translate}}        
      </button>
      
    </mat-dialog-actions>

  `,
  styles: [``],
})

export class LicenseModal implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public licenseData: any,
    private dialogRef: MatDialogRef<LicenseModal>,
  ) {}

  ngOnInit(): void {}

 
}

<div class="flex items-center !py-1 !px-3 sm:!px-6" mat-dialog-title >
  <h2 class="title md:headline m-0 flex-auto">
    {{  isEdit ? ('Edit Information' | translate) : ('Engineer Details' | translate ) }} 
  </h2>
  
  <button
    [matMenuTriggerFor]="companyMenu"
    mat-icon-button
    type="button"
    *ngIf="!isEdit"          
  >
    <mat-icon svgIcon="mat:more_vert"></mat-icon>
  </button>

  <button
    class="text-secondary flex items-center justify-center"
    mat-dialog-close
    (click)="dialogRef.close(true)"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="flex flex-col !max-h-[75vh] !px-3 sm:!px-6" data-modal-type="engineer-view" >
  <div class="block md:flex items-center justify-center gap-4 mb-2">
    <div class="relative"  *ngIf="isLoaded">
      <div class="w-28 h-28 sm:h-32 border border-primary-100 shadow sm:w-32 md:w-28 md:h-28  rounded-full group overflow-hidden m-auto">
        <img
          [src]="profileImageUrl"
          class="object-cover w-full h-full"
        />

        <input type="file" #fileInput (change)="onFileSelected($event)" accept=".jpeg,.png" style="display: none;" />
      </div>

      <div class="absolute bottom-0 right-0 z-10 flex rounded-full bg-primary-600 p-2 w-9 h-9 overflow-hidden items-center justify-center cursor-pointer" *ngIf="isEdit" (click)="triggerFileInput();">
        <mat-icon svgIcon="mat:edit" class="text-white icon-sm" ></mat-icon>
      </div>
    </div>
    <ngx-skeleton-loader
      count="1"
      [theme]="{ width: '111px', height: '111px' }"
      appearance="circle"
      *ngIf="!isLoaded"
    ></ngx-skeleton-loader>


    <div class="flex-grow">
      <div class="flex items-center justify-between mb-2 mt-3 md:mt-0" *ngIf="isLoaded">
        <h2 class="title md:headline"  >
          {{ localStorage.getItem("language") == 'en' ? engFormData.contactEng : engFormData.contactArb }}
          <mat-icon 
            [svgIcon]="engFormData.genderID == 1 ? 'mat:male' : 'mat:female'"                  
            [ngClass]="{'!text-blue-500': engFormData.genderID == 1, '!text-pink-500':engFormData.genderID != 1}" 
            class="!w-4 !h-4"
            [matTooltip]="engFormData.genderID == 1 ? ('Male' | translate) : ('Female' | translate)"
          ></mat-icon>
        </h2>
        <span [innerHTML]="engFormData.badge" class="hidden md:block"></span>
      </div>
      <div class="h-auto w-auto" *ngIf="!isLoaded">
        <ngx-skeleton-loader
          count="1"
          [theme]="{'border-radius': '4px', height: '24px' }"          
        ></ngx-skeleton-loader>
      </div>
      
      <div *ngIf="isLoaded">
        <div class="flex flex-col items-start justify-between md:flex-row md:items-center">
          <div class="flex items-center gap-2 justify-between w-full md:justify-start">
            <span class="font-bold"> {{ ('License No:' | translate ) }}</span>
            <span> {{ engFormData.engineerLicenseNo && engFormData.engineerLicenseNo != '' ? engFormData.engineerLicenseNo : ('Not Specified' | translate )}}</span>
          </div>
          <div class="flex items-center gap-2 justify-between w-full md:justify-end">
            <span  class="font-bold"> {{ ('License Exp Date:' | translate )}}</span>
            <span> {{ engFormData.licenseExpiryDate &&  engFormData.licenseExpiryDate != '' ? (engFormData.licenseExpiryDate | date) : ('Not Specified' | translate ) }}</span>
          </div>
        </div>
  
        <div class="flex flex-col items-start justify-between md:flex-row md:items-center">
          <div class="flex items-center gap-2 justify-between w-full md:justify-start">
            <span  class="font-bold"> {{ ('Personal ID:' | translate )}}</span>
            <span> {{ engFormData.cont_CPR && engFormData.cont_CPR != '' ? engFormData.cont_CPR : ('Not Specified' | translate ) }}</span>
          </div>
          <div class="flex items-center gap-2 justify-between w-full  md:justify-end">
            <span  class="font-bold"> {{ ('Personal ID Exp Date:' | translate )}}</span>
            <span> {{engFormData.cprExpDate && engFormData.cprExpDate != '' ? (engFormData.cprExpDate | date) : ('Not Specified' | translate ) }}</span>
          </div>
        </div>  
        <div class="flex flex-col items-start justify-between md:flex-row md:items-center" >
          <div class="flex items-center gap-2 justify-between w-full md:justify-start">
            <span  class="font-bold"> {{ ('Passport No:' | translate )}}</span>
            <span> {{ engFormData.passportNo && engFormData.passportNo != '' ? engFormData.passportNo : ('Not Specified' | translate ) }}</span>
          </div>
          <div class="flex items-center gap-2 justify-between w-full  md:justify-end">
            <span  class="font-bold"> {{ ('Passport Exp Date:' | translate )}}</span>
            <span> {{ engFormData.passportExpDt | date }}</span>
          </div>
        </div>
        <div class="flex flex-col items-start justify-between md:flex-row md:items-center " >
          <div class="flex items-center gap-2 justify-between w-full md:justify-start">
            <span  class="font-bold"> {{ ('Nationality:' | translate )}}</span>
            <span> {{ engFormData.nationality && engFormData.nationality != '' ? engFormData.nationality : ('Not Specified' | translate ) }}</span>
          </div>
        </div>
      </div>
      <div class="h-auto w-auto" *ngIf="!isLoaded">
        <ngx-skeleton-loader
          count="3"
          [theme]="{'border-radius': '4px', height: '17px' }"          
        ></ngx-skeleton-loader>
      </div>      
    </div>
  </div>

  <div class="flex items-center justify-start gap-2">
    <span class="font-bold"> {{ ('Discipline:' | translate )}}</span>
    <span *ngIf="isLoaded"> {{ engFormData.entityDiscipline != '' ?  engFormData.entityDiscipline : ('Not Specified' | translate ) }}</span>   
  </div>

  <mat-divider class="text-border m-4 mb-2"></mat-divider>
    <form [formGroup]="engFirstForm" *ngIf="isLoaded && engFirstForm"> <!---->
      <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-2 md:gap-x-6 mt-6">
        <mat-form-field *ngIf="isEdit && !isPersonalDisabled">
          <mat-label>
            {{ "Personal ID" | translate }}
          </mat-label>
          <input 
            formControlName="cont_CPR" 
            matInput 
          />
        </mat-form-field>
        <mat-form-field *ngIf="isEdit" >
          <mat-label>
            {{ "Personal ID Expiry Date" | translate }}
          </mat-label>
          <input
            [matDatepicker]="PersonalID"
            formControlName="cprExpDate"
            matInput
            [min]="currentDate"            
          />
          <mat-datepicker-toggle
            [for]="PersonalID"
            class="block"
            matIconPrefix
          ></mat-datepicker-toggle>
          <mat-datepicker #PersonalID> </mat-datepicker>
        </mat-form-field>
        <mat-form-field  *ngIf="isEdit && !isPassportDisabled">
          <mat-label>
            {{ "Passport No" | translate }}
          </mat-label>
          <input 
            formControlName="passportNo" 
            matInput 
          />
        </mat-form-field>
        <mat-form-field *ngIf="isEdit">
          <mat-label>
            {{ "Passport Expiry Date" | translate }}
          </mat-label>
          <input
            [matDatepicker]="passportExp"
            formControlName="passportExpDt"
            matInput
            [min]="currentDate"            
          />
          <mat-datepicker-toggle
            [for]="passportExp"
            class="block"
            matIconPrefix
          ></mat-datepicker-toggle>
          <mat-datepicker #passportExp> </mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>
            {{ "Email" | translate }}
          </mat-label>
          <input 
            formControlName="contactEmail" 
            matInput
            [readonly]="!isEdit" 
          />
        </mat-form-field>
        <mat-form-field class="grow">
          <mat-label>{{ 'Telephone' | translate }}</mat-label>
  
          <ngx-mat-intl-tel-input
            [cssClass]="'custom'"
            [preferredCountries]="['bh', 'sa']"  
            formControlName="contactTel" 
            #telInput
            [required]="true"
          ></ngx-mat-intl-tel-input> 
        </mat-form-field>
        <mat-form-field class="grow">
          <mat-label>{{ 'Whatsapp' | translate }}</mat-label>
  
          <ngx-mat-intl-tel-input
            [cssClass]="'custom'"
            [preferredCountries]="['bh', 'sa']"  
            formControlName="contactFax" 
            #whatsappInput
            [required]="true"
          ></ngx-mat-intl-tel-input> 
        </mat-form-field>

        <mat-form-field class="grow">
          <mat-label>{{ 'Mobile No' | translate }}</mat-label>
  
          <ngx-mat-intl-tel-input
            [cssClass]="'custom'"
            [preferredCountries]="['bh', 'sa']"  
            formControlName="contactMobile" 
            #mobileInput
            [required]="true"
          ></ngx-mat-intl-tel-input> 
        </mat-form-field>

      </div>


    </form>   
    <div class="h-auto w-auto mt-2"  *ngIf="!isLoaded">
      <ngx-skeleton-loader
        count="2"
        [theme]="{'border-radius': '4px', height: '56px' }"          
      ></ngx-skeleton-loader>
    </div>
    <div class="mb-2">
      <h2 class="title text-black">
        {{ "Address" | translate }}
      </h2>
    </div>
    <ng-container *ngIf="isLoaded">
      <ng-container *ngFor="let x of engFormData.address; index as i"  > 
        <div class="grid grid-cols-1">
          <mat-form-field>
            <mat-label>
              {{ x.title | translate }}
            </mat-label>
            <input  
              matInput 
              [disabled]="true"
              [value]="x.address6 ? x.address6 : 'Please enter your address (Street, City, State)'" 
            />
            <button 
              *ngIf="isEdit" 
              type="button" 
              mat-icon-button 
              matIconSuffix 
              (click)="openAddressForm(x, true, i)"
            >
              <mat-icon svgIcon="mat:edit"></mat-icon>
            </button>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>
    <div class="h-auto w-auto mt-2"  *ngIf="!isLoaded">
      <ngx-skeleton-loader
        count="2"
        [theme]="{'border-radius': '4px', height: '56px' }"          
      ></ngx-skeleton-loader>
    </div>



</mat-dialog-content>

<!--Skeleton Loader-->
<!-- <div class="!max-h-[75vh] !px-3 sm:!px-6">

</div> -->
<mat-divider class="text-border"></mat-divider>

<mat-dialog-actions align="end" >
  <button
    color="warn"
    mat-button
    type="button"      
    (click)="dialogRef.close(true)"
  >
    {{'Close' | translate}}        
  </button>
  <button
    (click)="saveEngineerForm()"
    mat-raised-button
    cdkFocusInitial
    color="primary"
    mat-button
    type="button"
    [disabled]="!isEdit || isFormSubmitted"
  >
    <span *ngIf="!isFormSubmitted">
      {{ 'Save Changes' | translate }} 
    </span>        
  <mat-progress-spinner 
    class="!w-5 !h-5"
    mode="indeterminate" 
    color="accent"
    *ngIf="isFormSubmitted"
  ></mat-progress-spinner> 
           
  </button>
</mat-dialog-actions>

<!--Following Time2Code Standards-->
<mat-menu
  #companyMenu="matMenu" 
  xPosition="before" 
  yPosition="below"
>
  <button
    mat-menu-item 
    (click)="viewHistory(engFormData.engineerID)"
    *ngIf="!isEdit"
  >
    <mat-icon svgIcon="mat:history"></mat-icon>
    <span> {{ 'View License History' | translate}} </span>
  </button>
  <button
    mat-menu-item 
    (click)="editEngineerData()"
  >
    <mat-icon svgIcon="mat:edit"></mat-icon>
    <span> {{'Edit Engineer Information' | translate}} </span>
  </button>
  <button
    mat-menu-item 
    *ngIf="modalData.engineerData.gradeUpgrade && modalData.engineerData.gradeUpgrade != 0"
    (click)="continueUser(modalData.engineerData.gradeUpgrade, modalData.engineerData.engineerID)"
  >
    <mat-icon svgIcon="mat:upgrade"></mat-icon>
    <span> {{ modalData.engineerData?.upgradeLabel }}  </span>
  </button>

</mat-menu>
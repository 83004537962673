import { 
    ChangeDetectorRef, 
    Component, 
    Inject, 
    OnInit
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDividerModule } from "@angular/material/divider";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { 
  Subscription, 
} from "rxjs";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { FormService } from "src/app/_services/form.service";
import { SNACKBAR_DURATION_NORMAL } from "src/static-data/constants";

@Component({
  selector: "feedback-modal",
  templateUrl: "./response-form.modal.html",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatFormFieldModule,
    FormsModule,
    MatSnackBarModule,
    TranslateModule,
  ]
})

export class ResponseActionModal implements OnInit {
  private subscriptions: Subscription = new Subscription();
  public commentMessage: string = '';
  public finalAppID: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialogRef: MatDialogRef<ResponseActionModal>,
    public snackbar: MatSnackBar,
    public cdRef: ChangeDetectorRef,
    public formSrv: FormService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    let appID = this.inputData.appID;
    const FormSub = this.formSrv.GetStatusInformation(appID).subscribe({
      next: (value: any) => {
        if(value.succeeded && value.data) {
          this.commentMessage = value.data.message;          
          this.finalAppID = value.data.appID;
        }
      },
      error: (err: any) => {

      }
    });

    this.subscriptions.add(FormSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public continueApplication() {
    if(this.finalAppID && this.finalAppID != '') {
      this.dialogRef.close({
        isSubmit: true, 
        appID: this.finalAppID
      });
    } else {
      let response: string = this.translate.instant("Could not open the application form");

      this.snackbar.open(response, undefined, { duration: SNACKBAR_DURATION_NORMAL });
    }
  }

}



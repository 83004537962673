import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { appRoutes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  provideHttpClient,
  withInterceptorsFromDi,
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { provideRouter, withInMemoryScrolling } from "@angular/router";
import { MatDialogModule } from "@angular/material/dialog";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatNativeDateModule } from "@angular/material/core";
import { provideIcons } from "./core/icons/icons.provider";
import { provideLuxon } from "./core/luxon/luxon.provider";
import {  MomentDateAdapter } from "@vex/utils/moment-date-adapter";
import { provideNavigation } from "./core/navigation/navigation.provider";
import { vexConfigs } from "@vex/config/vex-configs";
import { provideQuillConfig } from "ngx-quill";
import {
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { authInterceptorProviders } from "./_helpers/auth.interceptor";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { environment } from "src/environments/environment";
import { provideVex } from "@vex/utils/vex.provider";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { LoaderInterceptor } from "./_helpers/loader.interceptor";



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const translateModuleConfig: TranslateModuleConfig = {
  defaultLanguage: "en",
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
};

export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    authInterceptorProviders,
    { 
      provide: RECAPTCHA_V3_SITE_KEY, 
      useValue: environment.recaptcha.siteKey 
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    importProvidersFrom(
      BrowserModule,
      MatDialogModule,
      MatBottomSheetModule,
      MatNativeDateModule,
      HttpClientModule,
      RecaptchaV3Module,
      TranslateModule.forRoot(translateModuleConfig),
    ),
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),

    provideVex({
      /**
       * The config that will be used by default.
       * This can be changed at runtime via the config panel or using the VexConfigService.
       */
      config: vexConfigs.poseidon,
      /**
       * Only themes that are available in the config in tailwind.config.ts should be listed here.
       * Any theme not listed here will not be available in the config panel.
       */
      availableThemes: [
        {
          name: "Default",
          className: "vex-theme-default",
        },
        {
          name: "Teal",
          className: "vex-theme-teal",
        },
        {
          name: "Green",
          className: "vex-theme-green",
        },
        {
          name: "Purple",
          className: "vex-theme-purple",
        },
        {
          name: "Red",
          className: "vex-theme-red",
        },
        {
          name: "Orange",
          className: "vex-theme-orange",
        },
      ],
    }),
    provideNavigation(),
    provideIcons(),
    provideLuxon(),
    provideQuillConfig({
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["clean"],
          ["link", "image"],
        ],
      },
    }),
  ],
};




import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
  providedIn: "root",
})

export class ContactService {
    public isAuthenticated_B: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    get isAuthenticated_O() {
        return this.isAuthenticated_B.asObservable();
    }
    constructor(
        private router: Router,
        private http: HttpClient,
        public tokenStorageSrv: TokenStorageService,
    ) {
        const token = this.tokenStorageSrv.getToken();
        if (token) {
        this.isAuthenticated_B.next(true);
        }
    }
  
  
    public login(reqParam: any): Observable<any> {
        return this.http.post<any>(
            environment.apiUrl + `/Contact/Authenticate`,
            reqParam, { 
            responseType: "json" 
            }
        );    
    }

    public logout(): void {
        this.tokenStorageSrv.logoutUser();
        this.isAuthenticated_B.next(false);
        this.router.navigate(["/login"]);
        sessionStorage.removeItem("container-config");
    }

    public sendOTP(reqParam: any): Observable<any> {
        return this.http.post<any>(
        environment.apiUrl + '/Contact/SendOTP',
        reqParam, { 
            responseType: "json" 
        }
        );
    }

    public resendOTP(reqParam: any): Observable<any> {
        return this.http.post<any>(
        environment.apiUrl + '/Contact/ResendOTP',
        reqParam, { 
            responseType: "json" 
        }
        );
    }

    public changePassword(reqParam: any): Observable<any> {
        return this.http.post<any>(
        environment.apiUrl + '/Contact/ChangePassword',
        reqParam, { 
            responseType: "json" 
        }
        );
    }

    public forgotPassword(reqParam: any): Observable<any> {
        return this.http.post<any>(
        environment.apiUrl + '/Contact/ForgotPassword',
        reqParam, { 
            responseType: "json" 
        }
        );
    }
}

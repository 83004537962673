import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Params, Router, RouterLink } from "@angular/router";
import { fadeInUp400ms } from "@vex/animations/fade-in-up.animation";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ContainerService } from "src/app/_services/container.service";
import { MatSelectModule } from "@angular/material/select";
import { map, Observable, of, startWith, Subscription } from "rxjs";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha';
import { OtpModal } from "src/app/_modals/otp.modal";
import { MatDialog } from "@angular/material/dialog";
import { OTP } from "@vex/interfaces/dashboard.interface";
import { FormPanel, mapFailureError } from "@vex/enums/enumerations";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AutoFillDirective } from "src/app/_directives/auto-fill.directive";
import { NgxMatIntlTelInputComponent } from "ngx-mat-intl-tel-input";
import { WebsiteService } from "src/app/_services/website.service";
import { ContactService } from "src/app/_services/contact.service";
import { SNACKBAR_DURATION_LARGE, SNACKBAR_DURATION_NORMAL } from "src/static-data/constants";
import { fadeInRightContinousAnimation400ms } from "@vex/animations/fade-in-right.animation";
import { dropdownAnimation400ms } from "@vex/animations/dropdown.animation";
import { duration } from "moment";
import { splitNsName } from "@angular/compiler";
import { MatAutocompleteModule } from "@angular/material/autocomplete";



export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.value.password === control.value.passwordConfirm
    ? null
    : { PasswordNoMatch: true };
};

export const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.value;

  if (!password) {
    return null; // Don't perform validation if the password is empty
  }

  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+]).{8,}$/;

  return passwordRegex.test(password)
    ? null
    : { invalidPassword: true };
};

@Component({
  selector: "vex-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  animations: [
    fadeInUp400ms, 
    fadeInRightContinousAnimation400ms,    
  ],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    RouterLink,
    TranslateModule,
    NgxMatIntlTelInputComponent,
    MatSelectModule,
    MatSnackBarModule,
    RecaptchaV3Module,
    MatProgressSpinnerModule,
    AutoFillDirective,
    MatAutocompleteModule,

  ],
})

export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild('phoneInput') phoneInput!: NgxMatIntlTelInputComponent;
  private subscriptions: Subscription = new Subscription();

  public isFormSubmitted: boolean = false;
  public lang: string = 'en';
  public configData: any = null;
  public registrationType: string = '';
  public searchableDropdown_O: Observable<any[]> = new Observable<any[]>;
  public showExtraFormFields: boolean = false;
  public applicantTypes: any = null;
  public hintType: any[] = [
    { id: 1, response: "Applicant must be owner and Bahraini Engineer" },
    { id: 2, response: "Applicant must not be Bahraini Engineer, but Partner or Manager must be Engineer" },
    { id: 3, response: "Applicant company must be Licensed office." },
    { id: 4, response: "Bahraini Company of Foreign Office" },
    { id: 5, response: "Applicant must be GCC Engineer with 7 Years Experience" },
    { id: 6, response: "Bahraini Company Joint Venture Office" }
  ];

  public registerForm: FormGroup = this.fb.group({
    applicantType: [null, Validators.required],
    cpr: [null, Validators.required],
    email: [null],
    eoid: [null, Validators.required],
    contactMobile: [null], 
    natId: [null]
  });



  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    public containerSrv: ContainerService,
    public translateSrv: TranslateService,
    public websiteSrv: WebsiteService,
    public snackbar: MatSnackBar,
    public recaptchaSrv: ReCaptchaV3Service,
    private dialog: MatDialog,
    private tokenStorageService: TokenStorageService,
    private contacSrv: ContactService,
    private route: ActivatedRoute 
  ) {}

  public ngOnInit(): void {
    this.lang = localStorage.getItem('language') || 'en';
    const routeSub: Subscription = this.route.queryParams.subscribe((param: Params) => {
      this.registrationType = param['type'] || '';
    }); 


    this.subscriptions.add(routeSub);
      const ContactSub: Subscription = this.websiteSrv.getRegistrationSetup().subscribe({
        next: (value: any) => {
          if(value && value.succeeded) {
            
            this.configData = value.data;
            this.applicantTypes = this.configData.applicantTypes;
          } else {
            this.snackbar.open(
              this.translateSrv.instant("Error Happened While Fetching Data"),
              undefined,
              { 
                duration: SNACKBAR_DURATION_NORMAL
              }
            );
          }
        },
        error: (err: any) => {
          this.snackbar.open(
            err,
            undefined,
            { 
              duration: SNACKBAR_DURATION_NORMAL
            }
          );
        }
      });

      this.subscriptions.add(ContactSub);

      this.registerForm.get('eoid')?.valueChanges.subscribe(value => {
        this.showExtraFormFields = this.showExtraFields(value);
        if(this.showExtraFormFields) {
          this.addExtraFieldValidation();
        } else {
          this.removeExtraFieldValidation();
        }

        switch(value) {
          case 1: //Bahraini Office - Owner Licensed Engineer
          case 2: //Bahraini Office - Owner Non Licensed Engineer Individual
          case 3: //Bahraini Office - Non Licensed Office
          case 5: // GCC Office - Owner Licensed Engineer
            this.applicantTypes = [
              { id: 5, name: "Owner" }
            ];
            break;
          
          case 4: // Foreign Office
          case 6: // Joint Venture
            this.applicantTypes = this.configData.applicantTypes;
            break;
        }

        this.registerForm.get('applicantType')?.reset();
      });

      this.searchableDropdown_O = this.registerForm.get('natId')?.valueChanges.pipe(
        startWith(''),
        map((searchString: string) => this.filteredData(searchString, 'natId'))
      ) ?? of([]);
  }

  addExtraFieldValidation(): void {
    this.registerForm.get('email')?.setValidators([Validators.required, Validators.email]);
    this.registerForm.get('contactMobile')?.setValidators([Validators.required]);
    this.registerForm.get('natId')?.setValidators([Validators.required]);

    // Update form controls to revalidate with the new validators
    this.registerForm.get('email')?.updateValueAndValidity();
    this.registerForm.get('contactMobile')?.updateValueAndValidity();
    this.registerForm.get('natId')?.updateValueAndValidity();

  }

  public removeExtraFieldValidation() {
    this.registerForm.get('email')?.setValidators([]);
    this.registerForm.get('contactMobile')?.setValidators([]);
    this.registerForm.get('natId')?.setValidators([]);

    // Update form controls to revalidate with the new validators
    this.registerForm.get('email')?.updateValueAndValidity();
    this.registerForm.get('contactMobile')?.updateValueAndValidity();
    this.registerForm.get('natId')?.updateValueAndValidity();


    this.registerForm.get('email')?.reset();
    this.registerForm.get('contactMobile')?.reset();
    this.registerForm.get('natId')?.reset();
  }

  private filteredData(searchString: string, type: string): any[] {
    let filteredData: any[] = [];
    switch(type) {
      case 'natId':
        if (typeof searchString === 'string') {
          filteredData = this.configData.country.filter((option: any) =>
                option.name.toLowerCase().includes(searchString.toLowerCase()))
        } else {
          filteredData = this.configData.country.slice();
        } 
        break;
    }
    return filteredData;
  }



  public getHintType(): string {
    const EOID = this.registerForm.get('eoid')?.value;
    const selectedType = this.hintType.find((hint: any) => hint.id == EOID);
    return selectedType ? selectedType.response : '';
  }

  public showExtraFields(eoid: number) {
    return [2, 3, 4, 5, 6].includes(eoid);    
  }

    
  public displayFn(option: any): string {
    return (option && option.name) ? option.name : '';
  }

  public routeToHelp() {
    this.router.navigate(['help-get-started']);
  }

  public submitUser(): void {
    this.markFormGroupTouched(this.registerForm);  

    if(this.registerForm.valid) {
      let registerPayload: any = {
        applicantType: this.registerForm.get('applicantType')?.value || '',
        cpr: this.registerForm.get('cpr')?.value || '',
        eoid: this.registerForm.get('eoid')?.value || '',


        otp: "",
        reference: "",

        email: "",
        contactMobile: "",
        natID: 0,

        //not needed
        licenseNo: null,        
        contactSalutation: 0,
        name: "",
        nameArb: "",
        contactTel: "",
        genderID: 0,
        password: "",        
        etid: 0,
        customerType: 0,        
      }
      const natId = this.registerForm.get('natId')?.value;
      if(this.showExtraFormFields) {
        registerPayload.natID = natId.id || 0;
        registerPayload.email = this.registerForm.get('email')?.value || '';
        registerPayload.contactMobile = this.registerForm.get('contactMobile')?.value || '';
      } 
      

      this.isFormSubmitted = true;
      const recaptchaSub = this.recaptchaSrv.execute('importantAction').subscribe((token: string) => {
        if(token) {          
          let otpPayload = {
            applicantType: registerPayload.applicantType,
            eoid: registerPayload.eoid,
            cpr: registerPayload.cpr,
            email: registerPayload.email,
            natID: registerPayload.natID,
            contactMobile: registerPayload.contactMobile,
            reCaptcha: token
          };

          const otpSub =  this.websiteSrv.sendOTP(otpPayload).subscribe({
            next: (value: any) => {
              if(value && value.succeeded) {
                this.registerUser(registerPayload, value.data);   
              } else {        
                this.isFormSubmitted = false;        
                this.snackbar.open(mapFailureError(value.errorCode, this.translateSrv), undefined, { duration: SNACKBAR_DURATION_LARGE });
              }
            },
            error: (err: any) => {
              this.isFormSubmitted = false;
              this.snackbar.open(this.translateSrv.instant("Error Occurred while verifying your Initial Request"), undefined, { duration: SNACKBAR_DURATION_LARGE });
            }
          });
          this.subscriptions.add(otpSub);
        }
      });

      this.subscriptions.add(recaptchaSub);
    } else {
      this.snackbar.open("Please fill all the mandatory fields", undefined, {
        duration: SNACKBAR_DURATION_NORMAL
      });
    } 
  }




  // public toggleVisibility() {
  //   if (this.visible) {
  //     this.inputType = "password";
  //     this.visible = false;
  //     this.cd.markForCheck();
  //   } else {
  //     this.inputType = "text";
  //     this.visible = true;
  //     this.cd.markForCheck();
  //   }
  // }

  public ngOnDestroy() {
    this.registerForm.reset();
    this.subscriptions.unsubscribe();
  }






 

  private registerUser(registerPayload: any, responseData: any): void {  
    
    let otpPayload: any = {
      ref: responseData.reference,
      contactMobile: responseData?.contactMobile || '',
      email: responseData?.contactEmail || ''
    }

    //IF THE USER WILL TYPE THE EMAIL AND MOBILE NUMBER depending on the user selection.
    if(this.showExtraFormFields) {
      otpPayload.contactMobile = registerPayload.contactMobile;
      otpPayload.email = registerPayload.email;
    }  

    this.dialog.open(OtpModal, { 
      data: otpPayload,
    }).afterClosed().subscribe((otpData: OTP) => {
      this.isFormSubmitted = false;
      if (otpData) {        
        registerPayload.reference = otpData.reference;
        registerPayload.otp = otpData.otp;
        registerPayload.email = otpData.email;
        const registerSub = this.websiteSrv.registerUser(registerPayload).subscribe({
          next: (value: any) => {
            if(value && value.succeeded) {
                
               this.snackbar.open(
                this.translateSrv.instant("An email with your login credentials has been sent. Please use it to access your account."), 
                undefined, 
                { 
                  duration: SNACKBAR_DURATION_LARGE 
                });
                this.router.navigate(['login']);

              } else {
                this.isFormSubmitted = false; 
                this.snackbar.open(mapFailureError(value.data.errorCode, this.translateSrv), undefined, { duration: SNACKBAR_DURATION_NORMAL });
              }


              
          },
          error: (err: any) => {
            this.snackbar.open(this.translateSrv.instant("Error Occurred while verifying your OTP request"), undefined, { duration: SNACKBAR_DURATION_LARGE });
          }
        });
        this.subscriptions.add(registerSub);
      }
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
  
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

}

import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatDividerModule } from "@angular/material/divider";
import { OTP } from "@vex/interfaces/dashboard.interface";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { ContactService } from "../_services/contact.service";
import { SNACKBAR_DURATION_NORMAL } from "src/static-data/constants";
import { WebsiteService } from "../_services/website.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatRadioModule,
    FormsModule,
    TranslateModule,
    MatDialogModule, 
    MatDividerModule,
    MatSnackBarModule
  ],
  selector: "otp-modal",
  template: `
  <div class="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
    <header class="mb-8">
      <h1 class="text-2xl font-bold mb-3">
        {{ 'OTP Code Verification' | translate }}
      </h1>
      <div class="text-[15px] text-slate-800">
        {{ 'An OTP has been sent to your registered email and/or mobile. Please enter it below.' | translate }}
        <p class="flex mx-auto items-center gap-1 justify-center text-sm text-slate-500 mt-2">
          <span *ngIf="redactedEmail != ''">({{ redactedEmail }})</span>
          <span *ngIf="redactedEmail != '' && redactedNumber != ''">-</span>
          <span *ngIf="redactedNumber != ''">({{redactedNumber}})</span>
        </p>
      </div>
    </header>
    <div>
      <div class="flex items-center justify-center gap-2 md:gap-3">
        <input
          *ngFor="let i of [0, 1, 2, 3, 4, 5]; let index = index"
          type="text"
          class="otp-input w-10 h-10 text-lg p-0 md:w-14 md:h-14 md:text-2xl md:p-4"
          maxlength="1"
          [(ngModel)]="OTP[index]"
          #inputElements
          (keyup)="onInputKeyUp($event, index)"
        />
      </div>
      <div class="max-w-[260px] mx-auto mt-8 mb-5">
        <button 
          color="primary"
          mat-raised-button 
          (click)="submitOTP()"
        >
          {{ 'Submit OTP' | translate }}
        </button>
      </div>
  </div>
    <div class="text-sm text-slate-500 mt-2">
      {{ "Didn't receive code?" | translate }}
      <a class="font-medium text-primary-500 hover:text-primary-600 cursor-pointer" (click)="resendOTP()">
        {{ "Resend OTP" | translate }}
      </a>
    </div>

    <div class="text-sm text-slate-500 mt-4">
      {{ "If you no longer have access to your email or mobile number, please contact support to update your details." | translate }}
    </div>
  </div>

  `,
  styles: [``],
})

export class OtpModal implements OnInit {
  @ViewChildren('inputElements') inputElements!: QueryList<ElementRef<HTMLInputElement>>;
  private subscription: Subscription = new Subscription();

  public OTP: string[] = [];
  public companyType: number = 0;
  public redactedEmail: string;
  public redactedNumber: string;
  
  public otpData: OTP = {} as OTP;
  // public otp: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public diaologData: any, 
    private dialogRef: MatDialogRef<OtpModal>,
    public contactSrv: ContactService,
    private translate: TranslateService,
    public snackbar: MatSnackBar,
    public websiteSrv: WebsiteService
  ) {

    this.otpData.email = diaologData?.email || '';
    this.otpData.contactMobile = diaologData?.contactMobile || '';
    this.companyType = diaologData?.eoid || 0;
    this.redactedEmail = this.redactEmail(this.otpData.email);
    this.redactedNumber = this.redactNumber(this.otpData.contactMobile);

    this.otpData.reference = diaologData.ref;
  }

  ngOnInit(): void {}

  public submitOTP() {
    let emptyString =  this.OTP.filter((item: string) => item === ' ' || item === '' );

    if(this.OTP.length == 6 && emptyString.length == 0 ) {
      this.otpData.otp = this.OTP.join('');
      this.dialogRef.close(this.otpData);
    } else {
      let response: string = this.translate.instant("Please enter a valid digit");
      this.snackbar.open(response, undefined, { duration: SNACKBAR_DURATION_NORMAL });
      this.OTP = [];
      this.inputElements.first.nativeElement.focus();
    }
  }

  private redactNumber(str: string): string {
    if (str.length <= 3) {
      return str;  // Return the original string if it's 3 characters or less
    }

    let visiblePart = str.slice(-3);
    let asterisks = '*'.repeat(str.length - 3);
    return asterisks + visiblePart;
  }

  private redactEmail(email: string): string {
    const [localPart, domain] = email.split('@');

    if (localPart.length <= 2) {
      return email;  // Return the original email if the local part is 2 characters or less
    }
    const visiblePart = localPart.slice(0, 2);
    const asterisks = '*'.repeat(localPart.length - 2);
    return `${visiblePart}${asterisks}@${domain}`;
  }

  public onInputKeyUp(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    if (event.key === 'Backspace' && input.value === '') {
      this.OTP[index] = '';
      // this.OTP[index] = this.OTP.slice(0, -1);
      const previousInput = input.previousElementSibling as HTMLInputElement;
      if (previousInput) {
        previousInput.focus();
      }
      return;
    }

    if (input.value.length === input.maxLength) {
      this.OTP[index] = input.value;
      const nextInput = input.nextElementSibling as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      } 
    }
  }
  
  public resendOTP(): void {
    //TODO: SHOULD WE REMOVE THIS USERNAME???
    let reqParam = {
      username: this.diaologData.email,
      email: this.diaologData.email,
      contactMobile: this.diaologData.contactMobile
    };

    const otpSub =  this.websiteSrv.resendOTP(reqParam).subscribe({
      next: (value: any) => {
        if(value && value.succeeded) {
          this.otpData.reference = value.data.reference;
          this.clearInputs();
          this.inputElements.first.nativeElement.focus();
          let response: string = this.translate.instant("A new OTP has been sent to your email and/or mobile.");

          this.snackbar.open(response, undefined, { duration: SNACKBAR_DURATION_NORMAL });
        } else {
          this.snackbar.open(value.messages[0], undefined, { duration: SNACKBAR_DURATION_NORMAL });
        }
      },
      error: (err: any) => {
        let response: string = this.translate.instant("Error Occurred while resending OTP");

        this.snackbar.open(response, undefined, { duration: SNACKBAR_DURATION_NORMAL });
      }
    });

    this.subscription.add(otpSub);
  }
  

  private clearInputs(): void {
   this.OTP = [];
    this.inputElements.forEach(input => {
      input.nativeElement.value = '';
    });
  }


  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

<div class="vex-user-menu">
  <a
    (click)="viewProfile()"
    class="vex-user-menu-item !gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)"
    *ngIf="isCurrentUserEligiible()"  
  >
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:account_circle"></mat-icon>
    <div class="vex-user-menu-item__label">{{ 'My Profile' | translate }}</div>
  </a>
  <a
    (click)="changePassword()"
    class="vex-user-menu-item !gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:key"></mat-icon>
    <div class="vex-user-menu-item__label">{{ 'Change Password' | translate }}</div>
  </a>
  <a
    class="vex-user-menu-item !gap-2"
    (click)="openMoreInfoModal()"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)"
    *ngIf="appLevel && layoutSrv.isMobile()"
  >
    <mat-icon cclass="vex-user-menu-item__icon icon-sm"  [svgIcon]="appLevel.icon"></mat-icon>
    <div class="vex-user-menu-item__label">{{appLevel.label | translate }}</div>
    <div class="vex-user-menu-item__badge">{{ 'New' | translate }}</div>
  </a>
  <a
    (click)="logOut()"    
    class="vex-user-menu-item !gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)"
  >
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:logout"
    ></mat-icon>
    <div class="vex-user-menu-item__label">{{ 'Sign Out' | translate }}</div>
  </a>
</div>

import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";


@Injectable({
  providedIn: "root",
})

export class LoaderService {
  private loaderProgress_B = new BehaviorSubject<boolean>(false);
  get loaderProgress_O() {
    return this.loaderProgress_B.asObservable();
  }

  constructor() {}

  toggleProgress(progress: boolean) {
    if(progress != this.loaderProgress_B.value) {
      this.loaderProgress_B.next(progress);
    }
   
  }

}

import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { TokenStorageService } from "../_services/token-storage.service";
import { ContactService } from "../_services/contact.service";

const TOKEN_HEADER_KEY = "Authorization";
const TOKEN_LANG_KEY = "LanguageID";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private tokenStorageService: TokenStorageService,
    public contactSrv: ContactService,
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.tokenStorageService.getToken();
    let currentLangID: string =
      (localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "en") === "en"
        ? "1"
        : "2";

    if (token != null) {
      authReq = authReq.clone({
        headers: authReq.headers.set(TOKEN_HEADER_KEY, "Bearer " + token),
      });
      authReq = authReq.clone({
        headers: authReq.headers.set(TOKEN_LANG_KEY, currentLangID),
      });
    } else {
      authReq = authReq.clone({
        headers: authReq.headers.set(TOKEN_LANG_KEY, currentLangID),
      });
    }

    return next.handle(authReq).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.contactSrv.logout();
        }
        return throwError(err);
      }),
    );
    
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterLink, Scroll } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { Observable, Subscription, filter } from "rxjs";
import { fadeInRight400ms } from "@vex/animations/fade-in-right.animation";
import { WebsiteService } from "src/app/_services/website.service";

@Component({
  selector: "user-navigation",
  template: `
    <div class="sticky user-navigation-custom">
    <div *ngFor="let item of navigation_O | async" @fadeInRight class="w-[250px]">
      <h3
        *ngIf="item.route == '' && item.parent == 0"
        class="caption text-secondary font-medium mb-0 mt-6 px-2 select-none"
      >
        {{ item.name }}
      </h3>

      <a
        *ngIf="item.route != '' && item.parent == 0"
        (click)="currentSegment = item.route"
        [routerLink]="item.route"
        class="text-secondary rounded h-10 px-2 cursor-pointer hover:bg-primary-600/10 mt-2 font-medium flex items-center"
      >
        <span>{{ item.name }}</span>
      </a>
      <a
        *ngIf="item.route && item.parent != 0"
        (click)="currentSegment = item.route"
        [ngClass]="{
          'bg-primary-600/10 text-primary-600': currentSegment == item.route
        }"
        [routerLink]="item.route"
        class="rounded h-10 px-2 cursor-pointer hover:bg-primary-600/10 mx-3 my-1  font-medium flex items-center"
        matRipple
      >
        <mat-icon
          *ngIf="item.icon"
          [ngClass]="item.classes?.icon"
          [svgIcon]="item.icon"
          class="ltr:mr-3 rtl:ml-3 rtl:rotate-180"
        >
        </mat-icon>
        <span>{{ item.name }}</span>
      </a>
    </div>
    </div>

  `,
  styles: [
    `
    .user-navigation-custom {
      top: calc(var(--vex-toolbar-height) + 10px );
    }
    `
  ],
  animations: [fadeInRight400ms],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterLink, MatIconModule],
})

export class UserNavigation implements OnInit {

private subscriptions: Subscription = new Subscription();

public navigation_O: Observable<any[]> = this.websiteSrv.navigation_O;
public currentSegment: string = this.router.url;

  constructor(
    private websiteSrv: WebsiteService,
    public router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const RouteSub = this.router.events.pipe(
      filter((event): event is Scroll => event instanceof Scroll)
    ).subscribe((response: Scroll) => {
      let url: string[] = response.routerEvent.url.split('?');
      this.currentSegment = url[0];
      this.cdRef.detectChanges();      
    });

    this.subscriptions.add(RouteSub);
  }



  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { VexLayoutService } from "@vex/services/vex-layout.service";
import { VexConfigService } from "@vex/config/vex-config.service";
import { map, startWith, switchMap } from "rxjs/operators";
import { VexPopoverService } from "@vex/components/vex-popover/vex-popover.service";
import { Observable, Subscription, of } from "rxjs";
import { SidenavUserMenuComponent } from "./sidenav-user-menu/sidenav-user-menu.component";
import { VexScrollbarComponent } from "@vex/components/vex-scrollbar/vex-scrollbar.component";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { Router, RouterModule } from "@angular/router";
import { fadeInRight400ms } from "@vex/animations/fade-in-right.animation";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { UserNavigation } from "./user-navigation/user-navigation.component";
import { FormPanel, ImprovedApplicationStatus, RoutingTab } from "@vex/enums/enumerations";
import { environment } from "src/environments/environment";
import { FormService } from "src/app/_services/form.service";
import { ContactService } from "src/app/_services/contact.service";
import { WebsiteService } from "src/app/_services/website.service";

@Component({
  selector: "vex-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    VexScrollbarComponent,
    UserNavigation,
  ],
  animations: [fadeInRight400ms],
})
export class SidenavComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();
  @Input() collapsed: boolean = false;
  environment = environment;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;

  imageUrl$ = this.configService.config$.pipe(
    map((config) => config.sidenav.imageUrl),
  );

  userMenuOpen$: Observable<boolean> = of(false);
  public currentSegment: string = "/";
  public appLevel: any;

  public applicationStatus: any;
  ltMd$: Observable<boolean> = this.layoutService.ltMd$;
  // items$: Observable<NavigationItem[]> = this.contactService.navigation$;

  constructor(
    private contactService: ContactService,
    public tokenStorageSrv: TokenStorageService,
    private router: Router,
    private layoutService: VexLayoutService,
    private configService: VexConfigService,
    private websiteSrv: WebsiteService,
    private readonly popoverService: VexPopoverService,
  ) {
    this.currentSegment = this.router.url.replace("%20", " ");
  }

  ngOnInit() {
    let isNewUser = this.tokenStorageSrv.isNewUser();
    if(isNewUser) {
      let tempData: any = {
        label: "Continue Registration",
        icon: "mat:assignment",
        route: "/form/comp-form",
        param: { 
          formType: FormPanel.AddUser_Company,
          iseditable: true
        },
      }

      const FormSub = this.websiteSrv.getApplicationStatus().subscribe({
        next: (value: any) => {
          if (value && value.succeeded) {
            if( value.data.length > 0 && value.data[0].levelID != null ) {
              tempData = this.calculateApplicationLevel(value.data[0].levelID, value.data[0].appID, value.data[0].oid);
            } 

            this.appLevel = tempData;            
          } 
        }
      });

      this.subscriptions.add(FormSub);
    }
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  private calculateApplicationLevel(levelID: number, appID: string, oid: number ) {
    switch (levelID) {
      case ImprovedApplicationStatus.ApplicationFormPayment:
        return {
          label: "Continue Payment",
          icon: "mat:payments",
          route: "/checkout",
          param: { appID: appID }
        };
      case ImprovedApplicationStatus.ApplicationInProgress:
        return {
          label: "Application In Progress",
          icon: "mat:content_paste_search",
          route: "",
          levelID: levelID,
        };
      case ImprovedApplicationStatus.RequireFurtherInformation:
        return {
          label: "Require Further Information",
          icon: "mat:source_notes",
          route: "",
          levelID: levelID,
        };     
      
      case ImprovedApplicationStatus.ApplicationApproved:
        return {
          label: "Application Approved",
          icon: "mat:recommend",
          route: "",
          levelID: levelID,
        };
      case ImprovedApplicationStatus.ApplicationRejected:
        return {
          label: "Application Rejected",
          icon: "mat:gpp_bad",
          route: "",
          levelID: levelID,
        };
      case ImprovedApplicationStatus.ApplicationFinalDocUpload:
        return {
          label: "Require Final Document Upload",
          icon: "mat:upload",
          route: "",
          levelID: levelID,
        };
      case ImprovedApplicationStatus.ApplicationLicensePayment:
        return {
          label: "Continue Final Payment",
          icon: "mat:payments",
          route: "/checkout",
          param: { appID: appID }
        };
      case ImprovedApplicationStatus.AwaitingApproval:
        return {
          label: "Awaiting Approval",
          icon: "mat:badge",
          route: "",
          levelID: levelID,
        };
        
      default: 
        return {
          label: "Unknown Level",
          icon: "mat:unknown_document",
          route: ""
        };
    }
  }

  public navigateToHome() {
    this.router.navigate(['/' + RoutingTab.Home]);
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: SidenavUserMenuComponent,
        origin,
        data: this.appLevel,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: "center",
            originY: "top",
            overlayX: "center",
            overlayY: "bottom",
          },
        ],
      }),
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  public isActive(route: string) {
    return this.currentSegment == route;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

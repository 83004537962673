import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { LoaderService } from '../_services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderSrv: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const toggleLoading = request.headers.get('toggleLoading') === 'true';

    if (toggleLoading) {
      this.loaderSrv.toggleProgress(true);
    }

    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (toggleLoading && event instanceof HttpResponse) {
            this.loaderSrv.toggleProgress(false);
          }
        },
        error: (error) => {
          if (toggleLoading) {
            this.loaderSrv.toggleProgress(false);
          }
        }
      }),
    );
  }
}

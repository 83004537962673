import { Component } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { BehaviorSubject, Observable, Subscription, filter, map, switchMap } from "rxjs";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { VexConfigService } from "@vex/config/vex-config.service";
import { Breadcrumb } from "@vex/interfaces/dashboard.interface";
import { ContactService } from "./_services/contact.service";
import { WebsiteService } from "./_services/website.service";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [
    RouterOutlet, 
    TranslateModule
  ],
})

export class AppComponent {
  private subscriptions: Subscription = new Subscription();
  private navigation_O: Observable<any[]> = this.websiteSrv.navigation_O;
  private breadCrumb_B: BehaviorSubject<Breadcrumb> = this.websiteSrv.breadCrumb_B;

  constructor(
    public contactSrv: ContactService,
    public websiteSrv: WebsiteService,
    private _translate: TranslateService,
    private configService: VexConfigService,
    private router: Router,
  ) {
    
    _translate.setDefaultLang("en");

    let language = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";

    if (language) {
      if (language == "en" || language == "ar") {
        localStorage.setItem("language", language);
      } else {
        localStorage.setItem("language", "en");
      }

      _translate.use(language);

      if (language == "ar") {
        this.configService.updateConfig({
          direction: "rtl",
        });
      } else {
        this.configService.updateConfig({
          direction: "ltr",
        });
      }
    }

  }

  ngOnInit() {
    const routeSub =  this.router.events.pipe(
      filter(event => event instanceof NavigationEnd), 
      switchMap((event: any) => this.navigation_O.pipe(
        map((elem: any) => {
          const routeMap = new Map(elem.map((item: any) => [item.route.toLowerCase(), item]));
          const parentMap = new Map(elem.map((item: any) => [item.oid, item]));
          const currentItem: any = routeMap.get(event.url.replaceAll('%20', ' ').toLowerCase());

          let Breadcrumb: Breadcrumb = { title: "Dashboard", crumbs: [] };
          if(currentItem) {
            Breadcrumb.title = currentItem.name;
            if (currentItem.parent === 0) {
              Breadcrumb.crumbs.push({name: currentItem.name, url: currentItem.route });
            } else {
              const parentItem: any = parentMap.get(currentItem.parent); 
              if (parentItem) {
                Breadcrumb.crumbs.push({name: parentItem.name, url: parentItem.route });
                Breadcrumb.crumbs.push({name: currentItem.name, url: currentItem.route });
              }
            }
          } else {
            Breadcrumb.crumbs.push({name: "Membership", url: "" });
            Breadcrumb.crumbs.push({name: "Dashboard", url: "/dashboard" });
          }

          return Breadcrumb;
        })
      ))
    ).subscribe((breadcrumb: any) => {
      this.breadCrumb_B.next(breadcrumb);
    }); 

    this.subscriptions.add(routeSub);
    
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FormSegment, Stakeholder, formConfig, formValidation } from "@vex/interfaces/dashboard.interface";

@Injectable({
  providedIn: "root",
})

export class FormService {
  public Comp_FormSegment_B: BehaviorSubject<FormSegment[]> = new BehaviorSubject<FormSegment[]>([] as FormSegment[]);
    get Comp_FormSegment_O() {
      return this.Comp_FormSegment_B.asObservable();
    }

  public Eng_FormSegment_B: BehaviorSubject<FormSegment[]> = new BehaviorSubject<FormSegment[]>([] as FormSegment[]);
    get Eng_FormSegment_O() {
      return this.Eng_FormSegment_B.asObservable();
    }

  public parentFormData_B: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    get parentFormData_O() {
      return this.parentFormData_B.asObservable();
    }


  public storedCompFirstFormData_B: BehaviorSubject<any> = new BehaviorSubject<any>({});
    get StoredCompFirstFormData_O() {
      return this.storedCompFirstFormData_B.asObservable();
    }

  public storedEngFirstFormData_B: BehaviorSubject<any> = new BehaviorSubject<any>({});
    get storedEngFirstFormData_O() {
      return this.storedEngFirstFormData_B.asObservable();
    }

  constructor(private http: HttpClient) {}

  getFormPanelCaptions(formTypeID: string, isEngineerForm: boolean, appID: string, isDisabled: boolean): Promise<FormSegment[]> {
    let reqParam: string = `/Website/Custom/GetFormPanelCaptions?OID=${formTypeID}&appID=${appID}`;
    return new Promise((resolve: any, reject: any) => {
      this.http.get<any>(environment.apiUrl + reqParam, { responseType: "json" })
      .subscribe({
        next: (value: any) => {
          if (value.succeeded) {
            value.data.forEach((elem: any) => {
              if(elem.notes == undefined || elem.notes == null) {
                elem.notes = '';
              }
              let validation: formValidation = {
                isLastPannel: false,
                isFormSubmitted: false,
                isGoNextClicked: false,
                isFormValid: false,
                isDisabled: isDisabled
              } as formValidation;
               
              elem.validation = validation;
            });

            if(isEngineerForm) {
              this.Eng_FormSegment_B.next(value.data);
            } else {
              this.Comp_FormSegment_B.next(value.data);
            }
            resolve(value.data);
          } else {
            reject("err");
          }             
        },
        error: (err: any) => {
          reject(err);
        }
      });
      
    });

  }

  getFormFieldsInputs(appID: string, documnetID: number): Observable<any> {
    let reqParam: string = `/Website/Custom/GetFormFields?AppID=${appID}&DocCatId=${documnetID}`;
    return this.http.get<any>(environment.apiUrl + reqParam, {
      responseType: "json",
    });
  }

  addEngineer(EngData: any, parentOID: string | null): Observable<any> {
    let reqUrl: string = environment.apiUrl + "/Website/Custom/AddEngineer";
    if (parentOID) {
      reqUrl = reqUrl + `?parentOID=${parentOID}`;
    }
    
    return this.http.post<any>(reqUrl, EngData);
  }

  updateEngineer(formData: FormData): Observable<any> {
    let reqParam: string = environment.apiUrl + "/Website/Custom/UpdateEngineerData";

    return this.http.post<any>(reqParam, formData);
  }

  saveCaptionData(savedData: any): Observable<any>  {
    let reqParam: string = `/Website/Custom/SaveCaptionData?AppID=${savedData.appID}&IsEdit=${savedData.isEdit}&Submit=${savedData.isLastPannel}`;
    return this.http.post<any>(
      environment.apiUrl + reqParam,
      savedData.formData,
    );
  }

  getPendingForms(): Observable<any> {
    const headers = new HttpHeaders().set('toggleLoading', 'true');
    let reqParam: string = `/Website/Custom/GetPendingEngineerForms`;
    return this.http.get<any>(environment.apiUrl + reqParam, {
      headers: headers
    });
  }



  getEngineerData(appID: string, queryParam: string): Observable<any> {
    let reqParam: string = `/Website/Custom/GetEngineerFormData?AppID=${appID}` + queryParam;
    return this.http.get<any>(environment.apiUrl + reqParam, {
      responseType: "json",
    });
  }

  getStakeholderData(contactID: string): Observable<any> {
    let reqParam: string = `/Website/Custom/GetStakeholderData?contactID=${contactID}`;
    return this.http.get<any>(environment.apiUrl + reqParam, {
      responseType: "json",
    });
  }

  getDocumentFile(id: string, appID: string): Observable<any> {
    let reqParam: string = `/Website/Custom/GetDocumentFile?ID=${id}&AppID=${appID}`;
    return this.http.get<any>(environment.apiUrl + reqParam);
  }

  removeFormField(data: any): Observable<any> {
    let reqParam: string = `/Website/Custom/RemoveFormField?`;
    return this.http.post<any>(environment.apiUrl + reqParam, data);
  }

  upgradeEngineerLicense(oid: number, contactID: string): Observable<any> {
    let reqParam: string = `/Website/Custom/UpgradeEngineer`;
    return this.http.post<any>(environment.apiUrl + reqParam, {
      oid,
      contactID,
    });
  }

  submitApplication(appID: string, reSubmit: boolean): Observable<any>  {
    let reqParam: string = `/Website/Custom/SubmitApplication?AppID=${appID}&resubmission=${reSubmit}`;
    return this.http.post<any>(environment.apiUrl + reqParam,{});
  }

  upgradeCompanyLicense(oid: number): Observable<any> {
    let reqParam: string = `/Website/Custom/UpgradeCompany?OID=${oid}`;
    return this.http.post<any>(environment.apiUrl + reqParam, {});
  }

  getCompanyData(oid: number): Observable<any> {
    let reqParam: string = `/Website/Custom/GetCompanyBasicData?OID=${oid}`;
    return this.http.get<any>(environment.apiUrl + reqParam, {
      responseType: "json",
    });
  }

  saveCompanyData(savedData: FormData): Observable<any> {
    let reqParam: string = `/Website/Custom/SaveCompanyData`;
    return this.http.post<any>(environment.apiUrl + reqParam, savedData);
  }

  getApplicationFee(appID: string) {
    let reqParam: string = `/Website/Custom/GetApplicationFee?AppID=${appID}`;
    return this.http.get<any>(environment.apiUrl + reqParam, {
      responseType: "json",
    });
  }

  GetStatusInformation(appID: string) {
    let reqParam: string = `/Website/Custom/GetStatusInformation?appID=${appID}`;
    return this.http.get<any>(environment.apiUrl + reqParam, {
      responseType: "json",
    });
  }

  addStakeholder(personalID: string, categoryTitle: number, parentOID: string) {
    let reqUrl: string = environment.apiUrl + `/Website/Custom/AddStakeHolder?parentOID=${parentOID}`;
    return this.http.post<any>(reqUrl, {applicantCPR: personalID, categoryTitle: categoryTitle});
  }

  updateStakeholder(stakeholder: Stakeholder) {
    let reqUrl: string = environment.apiUrl + `/Website/Custom/UpdateStakeHolder`;
    return this.http.post<any>(reqUrl, stakeholder);
  }

  removeStakeholder(contactId: string[]) {
    let reqUrl: string = environment.apiUrl + `/Website/Custom/RemoveStakeholder`;
    return this.http.post<any>(reqUrl, {removeStakeholders: contactId } );
  }

  submitPayment(appID: string, paymentID: number): Observable<any> {
    let reqUrl: string = environment.apiUrl + `/Website/Custom/SubmitPG`;
    return this.http.post<any>(reqUrl, {
      appID,
      paymentID
    });
  }

  ValidatePG(paymentInformation: any): Observable<any> {
      let paramData: any = {
        PGTID: parseInt(paymentInformation.PGTID),
        CustomerId: 0,
        ContactId: 0,
        TransactionId: parseInt(paymentInformation.transactionID),
        ResultIndicator: paymentInformation.resultIndicator
      };
    return this.http.post<any>(`${environment.apiUrl}/Website/Custom/ValidatePG`, paramData);
  }
}

<div 
  #userProfileRef 
  (click)="openProfileMenu(userProfileRef)"  
  class="m-2 flex items-center gap-2 rounded cursor-pointer relative transition duration-400 ease-out-swift select-none py-1 px-2 hover:bg-hover min-w-56 max-w-80 text-ellipsis"
  matRipple 
  *ngIf="(isAuthenticated_O | async)"
>
  <div>
    <div class="h-9 w-9 rounded-full overflow-hidden " *ngIf="((loggedInUser | async ).avatarImage != null && (loggedInUser | async ).avatarImage != ''); else defaultAvatar">
      <img alt="User Avatar" class="w-full h-full object-cover" [src]="( enviroment.apiUrl + '/File/Processor?cipherText=' + (loggedInUser | async ).avatarImage)" />
    </div>
    <ng-template #defaultAvatar>
      <div class="h-9 w-9 rounded-full overflow-hidden ">
        <img alt="User Avatar" class="w-full h-full object-cover" [src]="'/assets/img/avatars/avatar.png'" />
      </div>
    </ng-template>
  </div>  
  <div class="vex-sidenav-user__content flex-auto text-ellipsis overflow-hidden">
    <div class="vex-sidenav-user__title text-ellipsis overflow-hidden"> {{ (loggedInUser | async ).employeeName }}</div>
    <div class="vex-sidenav-user__subtitle text-xs text-ellipsis overflow-hidden">{{ (loggedInUser | async ).title }}</div>
  </div>
  <mat-icon
    class="vex-sidenav-user__dropdown-icon flex-none"
    svgIcon="mat:arrow_drop_down"></mat-icon>
</div>
<a
  [routerLink]="['/login']" 
  class="flex items-center rounded justify-center gap-2 p-2  !cursor-pointer relative transition duration-400 ease-out-swift select-none hover:bg-primary-600 bg-hover/10"
  matRipple
  *ngIf="!(isAuthenticated_O | async)"
>
  <label class="body-1 font-medium !cursor-pointer hidden sm:block"> {{'Login' | translate }} </label>
  <mat-icon 
    svgIcon="personal:door_open" 
    class="icon-sm"
  ></mat-icon>
</a>
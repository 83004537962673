import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule, MatRippleModule } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ContainerService } from "../_services/container.service";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { MatMenuModule } from "@angular/material/menu";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";
import { passwordValidator } from "../_components/register/register.component";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { OtpModal } from "./otp.modal";
import { mapFailureError } from "@vex/enums/enumerations";
import { OTP } from "@vex/interfaces/dashboard.interface";
import { TokenStorageService } from "../_services/token-storage.service";
import { ContactService } from "../_services/contact.service";
import { SNACKBAR_DURATION_LARGE, SNACKBAR_DURATION_NORMAL } from "src/static-data/constants";


const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.value.password === control.value.repeatpassword
    ? null
    : { PasswordNoMatch: true };
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRippleModule,
    MatRadioModule,
    FormsModule,
    TranslateModule,
    MatOptionModule,
    MatDialogModule, 
    MatDividerModule,
    MatSelectModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  selector: "change-password-modal",
  template: `
    <form [formGroup]="changPasswordForm">
  <div class="flex items-center justify-between !px-3 sm:!px-6"  mat-dialog-title >
    <h4 class="m-0 flex-auto"> 
      {{ "Change Password" | translate }} 
    </h4>

    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col !pb-0 !px-3 sm:!px-6 !max-h-[70vh]">
    <div class="grid grid-cols-1 mt-6">
      <mat-form-field>
        <mat-label>
          {{ "Current Password" | translate }}
        </mat-label>  
        <input
          [type]="inputType"
          formControlName="prevPassword"
          matInput
          required
        />
        <button
          (click)="toggleVisibility()"
          mat-icon-button
          matIconSuffix
          [matTooltip]="'Toggle Visibility' | translate "
          type="button"
        >
          <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
        </button>  
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'New Password' | translate }}</mat-label>
        <input
          [type]="inputType"
          formControlName="password"
          matInput
          required
        />
        <button
          (click)="toggleVisibility()"
          mat-icon-button
          matIconSuffix
          [matTooltip]="'Toggle Visibility' | translate "
          type="button"
        >
          <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
        </button>       
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'Confirm Password' | translate }}</mat-label>
        <input
          [type]="inputType"
          formControlName="repeatpassword"
          matInput
          required
        />
        <button
          (click)="toggleVisibility()"
          mat-icon-button
          matIconSuffix
          [matTooltip]="'Toggle Visibility' | translate "
          type="button"
        >
          <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
        </button>
        <mat-error *ngIf="changPasswordForm.hasError('PasswordNoMatch') && changPasswordForm.get('repeatpassword')?.touched">
          {{ 'Password do not match' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <mat-label class="text-xs">
      <b>{{ 'Password Must Contain:' | translate }}</b>
      <ul class="mb-4">
        <li>{{ 'At Least One Uppercase and One Lowercase Letter.' | translate }}</li>
        <li>{{ 'At Least One Number.' | translate }}</li>
        <li>{{ 'At Least One Special Character.' | translate }}</li>
        <li>{{ 'Password Length (8 Letters Min).' | translate }}</li>
      </ul>
    </mat-label>

  </mat-dialog-content>
  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-actions align="end" >
    <button 
      mat-button 
      mat-dialog-close 
      type="button"
    >
      {{'Cancel' | translate}}
    </button>

    <button
      color="primary"
      mat-flat-button
      (click)="verifyPassword()" 
      [disabled]="isFormSubmitted"     
    >
      <span *ngIf="!isFormSubmitted" >{{ 'Change Password' | translate }}</span>
        
        <mat-progress-spinner 
          class="!w-5 !h-5"
          mode="indeterminate" 
          color="accent"
          *ngIf="isFormSubmitted"
        ></mat-progress-spinner> 
      
    </button>
  </mat-dialog-actions>
</form>

<!--Following Time2Code Standards-->


  `,
  styles: [``],
})



export class ChangePasswordModal implements OnInit {
  
  private subscriptions: Subscription = new Subscription();
  
  // public formSetup: any;
  // public isCustomTitle: boolean = false;

  public inputType: string = "password";
  public visible: boolean = false;
  public changPasswordForm: FormGroup;
  public isFormSubmitted: boolean = false;



  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ChangePasswordModal>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public containerSrv: ContainerService,
    public snackbar: MatSnackBar,
    public cdRef: ChangeDetectorRef,
    public recaptchaSrv: ReCaptchaV3Service,
    public contactSrv: ContactService,
    public translateSrv: TranslateService,
    public tokenStorageService: TokenStorageService
  ) {

      this.changPasswordForm = this.fb.group({
          prevPassword: [null,  Validators.required],
          password: [null,  [Validators.required, passwordValidator]],
          repeatpassword: [null, [Validators.required, passwordValidator]]  
        },
        { validators: [confirmPasswordValidator ] }
      );
  }

  ngOnInit() {}

  public toggleVisibility() {
    if (this.visible) {
      this.inputType = "password";
      this.visible = false;
      this.cdRef.markForCheck();
    } else {
      this.inputType = "text";
      this.visible = true;
      this.cdRef.markForCheck();
    }
  }


  public verifyPassword(): void {
    this.markFormGroupTouched(this.changPasswordForm);
    if(this.changPasswordForm.valid) {
      let payloadData = this.changPasswordForm.value;

      this.isFormSubmitted = true;

      const otpSub =  this.contactSrv.changePassword(payloadData).subscribe({
        next: (value: any) => {
          if(value && value.succeeded && value.data.success) { 
            this.verifyOTP(payloadData, value.data);                         
          } else {        
            this.isFormSubmitted = false;   
            this.snackbar.open(mapFailureError(value.errorCode, this.translateSrv), undefined, { duration: SNACKBAR_DURATION_NORMAL });
          }
        },
        error: (err: any) => {
          this.isFormSubmitted = false;
          let response: string = this.translateSrv.instant("Error Occurred while verifying your Initial Request");
          this.snackbar.open(response, undefined, { duration: SNACKBAR_DURATION_LARGE });
        }
      });

      this.subscriptions.add(otpSub);

    }   
  }

  private verifyOTP(payloadData: any, response: any): void {    
    this.dialog.open(OtpModal, { 
      data: {
        ref: response.reference,
        contactMobile: response.contactMobile,
        email: response.contactEmail
      }
    }).afterClosed().subscribe((otpData: OTP) => {
      this.isFormSubmitted = false;
      if (otpData) {        
        payloadData.authKey = otpData.reference;
        payloadData.otp = otpData.otp;

        const registerSub = this.contactSrv.changePassword(payloadData).subscribe({
          next: (value: any) => {
            if(value && value.succeeded) {
              if(value.data.success) {        
                if(value.data.authenticationResponse) {
                  this.tokenStorageService.saveToken(value.data.authenticationResponse.token);
                  this.tokenStorageService.saveUser(value.data.authenticationResponse);

                  this.dialogRef.close();
                  let response: string = this.translateSrv.instant("Your Password has been updated Sucessfully");
                   this.snackbar.open(response , undefined, { duration: SNACKBAR_DURATION_NORMAL });
                } 

                this.changPasswordForm.reset();
                

              } else {
                this.isFormSubmitted = false; 
                let response: string = this.translateSrv.instant("OTP Verification Failed");
                this.snackbar.open(response, undefined, { duration: SNACKBAR_DURATION_NORMAL });
              }
              
            } else {
              let response: string = this.translateSrv.instant("OTP Verification Failed");
              this.snackbar.open(response, undefined, { duration: SNACKBAR_DURATION_NORMAL });
              //todo: show error 
            }
          },
          error: (err: any) => {
            let response: string = this.translateSrv.instant("Error Occurred while verifying your OTP request");
            this.snackbar.open(response, undefined, { duration: SNACKBAR_DURATION_LARGE });
          }
        });

        this.subscriptions.add(registerSub);
      }
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
  
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }



  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}

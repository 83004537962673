export const environment = {
    apiUrl: 'https://imp.erpamthal.com/crpepapi', //Staging
    // apiUrl:  'https://imp.erpamthal.com/MUNAPI', //MUN PORTAL
 
    // apiUrl: 'https://cdncrpep.erpamthal.com/api', //Production
    // apiUrl: 'https://localhost:44344', //Local
    customerLogo: 'https://cdncrpep.erpamthal.com/CustomersLogos/',
    recaptcha: {
        siteKey: '6LcZY5gfAAAAAFTk3QMG0wz19BCS4TJeTw2_FmRR',
    },
    paymentUrl: 'https://payment.amthalgroup.com/credimax',
};

import { AuthGuard } from "./_guards/auth.guard";
import { NoAuthGuard } from "./_guards/no-auth.guard";
import { LayoutComponent } from "./layouts/layout/layout.component";
import { VexRoutes } from "@vex/interfaces/vex-route.interface";

export const appRoutes: VexRoutes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        canActivate: [AuthGuard],
        children: [
          { path: "", loadChildren: () => import("../app/pages/home/home.routes") },
        ],
      },
      {
        path: "login",
        canActivate: [NoAuthGuard],
        loadComponent: () => import("../app/_components/login/login.component").then((m) => m.LoginComponent)
        
      },
      {
        path: "register",
        canActivate: [NoAuthGuard],
        loadComponent: () => import("../app/_components/register/register.component").then((m) => m.RegisterComponent)        
      },
      {
        path: "forgot-password",
        canActivate: [NoAuthGuard],
        loadComponent: () => import("../app/_components/forgot-password/forgot-password.component").then((m) => m.ForgotPasswordComponent)        
      },

      {
        path: "form",
        canActivate: [AuthGuard],
        loadChildren: () => import("./pages/forms/form.module").then((m) => m.FormModule)
      },
      {
        path: "checkout",
        canActivate: [AuthGuard],
        loadComponent: () => import("./pages/checkout/checkout.component").then((m) => m.CheckoutComponent),
      },
      {
        path: "help-get-started",
        loadComponent: () => import("./pages/get-started/get-started.component").then((m) => m.GetStartedComponent),
      },
      {
        path: "**",
        loadComponent: () => import("../app/_components/error-404/error-404.component").then((m) => m.Error404Component),
      },
    ],
  },
];
